import { render, staticRenderFns } from "./inputComponent.vue?vue&type=template&id=a39a48ca&scoped=true&"
import script from "./inputComponent.vue?vue&type=script&lang=js&"
export * from "./inputComponent.vue?vue&type=script&lang=js&"
import style0 from "./inputComponent.vue?vue&type=style&index=0&id=a39a48ca&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a39a48ca",
  null
  
)

export default component.exports