<template>
  <el-row style="height: 210px" v-show="show === false">
    <!-- 工具栏 -->
    <el-col :span="24" class="tools">
      <uploadComponent
        style="float: left"
        :type="'image/*'"
        :icon="'el-icon-picture-outline'"
        v-on="$listeners"
        :tool="toolType1"
      />
      <uploadComponent
        style="float: left"
        :type="'file'"
        :icon="'el-icon-folder-add'"
        v-on="$listeners"
        :tool="toolType2"
      />
      <i class="el-icon-crop" @click="demo" />
      <i class="el-icon-folder left-button" @click="tofileEvent" />
    </el-col>
    <!-- 文字输入框 -->
    <el-col :span="24" class="input-box">
      <areatextComponent class="input-conteng" :value.sync="content" />
    </el-col>
    <el-col :span="24" style="padding: 0px 18px">
      <el-button type="primary" size="small" class="send-btn" @click="sendMessage">发送</el-button>
    </el-col>
    <el-dialog
      title="发送截图"
      :visible.sync="dialogVisible"
      width="30%"
      :modal="modal"
      top="20vh"
      style="text-align: center"
    >
      <el-image :src="img" fit="fill" style="height: 300px" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sendFile()">确 定</el-button>
      </span>
    </el-dialog>
  </el-row>
</template>
<script>
import ScreenShort from 'js-web-screen-shot';
import areatextComponent from '@/views/common/es/textarea';
import uploadComponent from './uploadFile.vue';
export default {
  name: 'inputComponent',
  components: {
    areatextComponent,
    // VueCropper,
    uploadComponent
  },
  props: {
    show: {
      type: Boolean
    }
  },
  data() {
    return {
      content: '',
      dialogVisible: false,
      modal: false,
      img: '',
      toolType1: 'img',
      toolType2: 'file'
    };
  },
  methods: {
    tofileEvent() {
      this.$emit('update:show', true);
    },
    sendMessage() {
      let message = this.content;
      this.$emit('sendMessage', message);
      this.content = '';
    },
    sendFile() {
      let arr = this.img.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      let file = new File([u8arr], `${new Date().getTime()}.png`, { type: 'image/png' });
      let formData = new FormData();
      formData.append('file', file);
      this.$emit('sendFile', formData);
      this.dialogVisible = false;
    },
    completeCallback(data) {
      this.img = data;
      this.dialogVisible = true;
    },
    demo() {
      let vm = this;
      new ScreenShort({
        enableWebRtc: true,
        completeCallback: vm.completeCallback,
        closeCallback: vm.closeCallback,
        level: 100
      });
    }
  },
     mounted() { this.$store.commit('changeCopy',true)}
};
</script>
<style lang="less" scoped>
.tools {
  height: 40px;
  padding: 11px 18px 0px;
  /deep/ i {
    line-height: 30px;
    font-size: 22px;
    margin-right: 28px;
    color: #409eff;
  }
  /deep/ i:hover {
    cursor: pointer;
  }
  .left-button {
    margin-right: 0px;
    float: right;
  }
}
.input-box {
  height: 110px;
  .input-conteng {
    background-color: #212025;
    border: 0px;
    color: #ffffff;
    width: 100%;
    padding: 0 18px 5px;
  }
  .input-conteng:focus {
    outline: 0px;
  }
}
.send-btn {
  width: 80px;
  float: right;
}
/deep/ .el-dialog__header {
  padding: 8px 20px;
  background-color: #171719;
}
/deep/ .el-dialog__title {
  font-size: 16px;
  color: #ffffff;
}
/deep/ .el-dialog__headerbtn {
  top: 10px;
}
</style>