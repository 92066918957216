<template>
  <div>
    <ul class="clearfix" :style="topValue">
      <li v-for="(item, i) in menus" :key="i" @click="choice.call(this, i, item.font)">
        <div class="image">
          <img :src="item.url" />
        </div>
        <p>{{ item.font }}</p>
      </li>
    </ul>

    <!-- <el-menu>
      <el-menu-item v-for="(item, i) in menus" :key="i" >
        <div class="image" @click="choice.call(this, i, item.font)">
          <img :src="item.url" />
        </div>
        <p>{{ item.font }}</p>
      </el-menu-item>
    </el-menu> -->
    <el-drawer
      class="right-menu-drawer"
      ref="drawer"
      :visible.sync="drawer"
      :direction="direction"
      :with-header="false"
      :modal="false"
      :show-close="true"
      :before-close="handleClose"
    >
      <div class="icon-style" @click="closeModal"><i class="el-icon-caret-right"></i></div>
      <el-tabs v-model="tabSelected" :stretch="true" @tab-click="handleClick">
        <el-tab-pane label="备注" name="备注">
          <rightMenuUpdate :planName="planName" />
        </el-tab-pane>
        <el-tab-pane label="照片" name="照片">
          <photo />
        </el-tab-pane>
        <el-tab-pane label="分步" name="分步">
          <stepTable :totalstep.sync="totalstep" :planIndext.sync="planIndext" />
        </el-tab-pane>
        <el-tab-pane label="概况" name="概况">
          <generalSituation />
        </el-tab-pane>
        <el-tab-pane label="反馈" name="反馈">
          <feedbackComponent ref="feedback" />
        </el-tab-pane>
      </el-tabs>
    </el-drawer>
  </div>
</template>
<script>
import rightMenuUpdate from '@/views/common/es/rightMenuUpdate';
import photo from '@/views/common/es/rightMenu/photo';
import generalSituation from '@/views/common/es/rightMenu/generalSituation';
import feedbackComponent from '@/views/common/es/rightMenuFeedback';
import stepTable from '@/views/common/es/rightMenu/stepTable';

export default {
  name: 'rightMenu',
  props: {
    planName: {
      type: String
    },
    totalstep: {
      type: Array
    },
    planIndext: {
      type: Number
    }
  },
  data() {
    return {
      menus: [
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/右侧/normal (1).png`), //显示的图片
          font: '备注' //显示的文字
        },
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/右侧/normal (2).png`),
          font: '照片'
        },
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/右侧/normal (3).png`),
          font: '分步'
        },
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/右侧/normal (4).png`),
          font: '概况'
        },
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/右侧/normal (5).png`),
          font: '反馈'
        }
      ],
      tabSelected: '修改',
      direction: 'rtl',
      drawer: false,
      scrollTop: 0
    };
  },
  computed: {
    topValue() {
      return { top: 146 - this.scrollTop + 'px', background: '#171719'};
    }
  },
  watch: {
    tabSelected() {
      if (this.tabSelected === '反馈') {
        let vm = this;
        this.$nextTick(() => {
          vm.$refs.feedback.fileHistory = false;
          setTimeout(() => {
            vm.$refs.feedback.scrollToBottom();
          }, 100);
        });
      }
    }
  },
  async created() {},
     mounted() { this.$store.commit('changeCopy',true)
    let vm = this;
    window.onscroll = function() {
      vm.scrollTop = document.documentElement.scrollTop;
    };
  },
  components: {
    photo,
    rightMenuUpdate,
    generalSituation,
    feedbackComponent,
    stepTable
  },
  methods: {
    choice(i, font) {
      console.log('right menu', i, font);
      this.tabSelected = font;
      this.drawer = true;
    },
    handleClose() {
      return;
    },
    closeModal() {
      this.$refs.drawer.closeDrawer();
      this.drawer = false;
    },
    handleClick(tab, event) {
      console.log('right menu', tab, event);
    }
  }
};
</script>
<style scoped lang="less">
/deep/ .el-drawer__body {
  padding: 15px 0px !important;
}
ul {
  position: fixed;
  top: 146px;
  right: 0;
  margin: 0 auto;
  width: 100px;
  height: calc(100% - 215px);

  li {
    height: 15%;
    min-height: 65px;
    position: relative;
    text-align: center;
    cursor: pointer;
    margin-top: 25px;
    .image {
      width: 100%;
      height: 70%;
      position: relative;

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 50%;
        max-height: 80%;
        padding: 10px;
        border-radius: 10px;
      }
    }

    p {
      height: 25%;
      line-height: 25%;
      margin-top: 0px;
      width: 100%;
      text-align: center;
      color: #aaadb1;
    }
    &:hover {
      background: #196dba;
    }
  }
}

.right-menu-drawer {
  z-index: 0 !important;
  position: absolute;
  height: calc(100% - 21px);
  pointer-events: none;
}
.right-menu-drawer {
  /deep/ .el-drawer {
    background: #212124;
    width: 596px !important;
    box-shadow: none;
    pointer-events: all;
  }
  /deep/ .el-drawer__body {
    padding: 15px;
  }
  /deep/ .el-tabs__item {
    color: #aaadb1;
  }
  /deep/ .el-tabs__item.is-active {
    color: #409eff;
  }
  /deep/ .el-tabs__item:focus.is-active.is-focus:not(:active) {
    box-shadow: 0 0 0 0;
  }
  /deep/ .el-tabs__nav-wrap::after {
    background: #212025;
  }
}
/deep/ .el-tabs__item {
  font-size: 16px;
  padding-left: 30px !important;
  padding-right: 30px !important;
  &:nth-child(2) {
    margin-left: 12px;
  }
  &:last-child {
    margin-right: 12px;
  }
}
/deep/ .el-tabs__header {
  margin: 16px 0px 20px;
}
/deep/ .el-tabs__active-bar {
  width: 48px !important;
  height: 4px !important;
  margin-left: 14px !important;
}
.icon-style {
  width: 16px;
  height: 50px;
  position: relative;
  float: left;
  background: #1a91ff;
  line-height: 50px;
  cursor: pointer;
  border-radius: 0px 15px 15px 0px;
  z-index: 999;
}
.close-style {
  height: calc(100vh + 30px);
  width: 16px;
  float: left;
  margin-top: -15px;
  background: #2e2f33;
}
</style>
<style>
@media screen and (max-height: 650px) {
  ul li {
    margin-top: 0px !important;
  }
}
@media screen and (min-height: 650px) and (max-height: 800px) {
  ul li {
    margin-top: 10px !important;
  }
}
</style>
